import React from 'react';
import styled from 'styled-components';
import { Box, Container, Flex } from 'components';
import colors from 'config/theme/colors';
import Logo from 'assets/images/logo.png';
import DesktopMenu from './DesktopMenu';
import MobileMenu from './MobileMenu';
import { mediaQueries } from '../../config/theme/base';
import { Link } from 'react-router-dom';

const LogoWrapper = styled.div`
    flex: 1;
    width: 100%;
    color: ${ colors.blue };
    font-size: 36px;
    font-weight: bold;
    
    ${ mediaQueries.md }{
        padding-left: 1rem;
        width: auto;
    }
`;

const StyledLogo = styled.img`
    height: 120px;
`;

const PageHeader: React.FC = () => {
    return (
        <Box
            p={ 2 }
            width='100%'
        >
            <Container>
                <Flex
                    alignItems='center'
                    justifyContent='space-between'
                >
                    <LogoWrapper>
                        <Link to='/'>
                            <StyledLogo src={ Logo } alt="Goge"/>
                        </Link>
                    </LogoWrapper>

                    <div>
                        <DesktopMenu/>
                        <MobileMenu/>
                    </div>
                </Flex>
            </Container>
        </Box>
    );
};

export default PageHeader;