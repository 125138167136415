import React from 'react';
import { Flex, Page, Text } from 'components';

const NotFound = () => {
    return (
        <Page>
            <Flex
                flexDirection='column'
                alignItems='center'
                justifyContent='center'
            >
                <Text mb={ 2 } fontSize='24px'>Oops 😅</Text>
                <Text mb={ 40 }>Page not found!</Text>
                <a href='/'>
                    Back Home
                </a>
            </Flex>
        </Page>
    );
};

export default NotFound;
