import React, { useState } from 'react';
import styled from 'styled-components';
import { CheckmarkIcon, CopyIcon, Flex, Text } from 'components';
import colors from 'config/theme/colors';
import { mediaQueries } from 'config/theme/base';
import { TOKEN_ADDRESS } from 'config/constants';

const ContentWrapper = styled.div`
    margin-top: 3rem;
    
    ${ mediaQueries.md }{
        margin-top: 0;
    }
`;

const ContractAddressWrapper = styled( Flex )`
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    ${ mediaQueries.md }{
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 1rem;
    }
`;

const CopiedMessage = styled( Flex )`
    align-items: center;
    justify-content: space-between;
    color: ${ colors.blue };
    margin-top: 0.25rem;
`;

const LinksWrapper = styled( Flex )`
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    
    & > a:first-child {
        margin-bottom: 1rem;
    }
    
    ${ mediaQueries.md }{
        flex-direction: row;
        justify-content: flex-start;

        & > a:first-child {
            margin-bottom: 0;
            margin-right: 1rem;
        }
    }
`;

const Link = styled.a`
    display: block;
    border: 0 none;
    border-bottom: 4px solid black;
    border-radius: 16px;
    font-family: 'Comic Neue', cursive;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    padding: 16px 36px;
    margin: 0;
    cursor: pointer;
    color: white;
    
    &:hover {
        border-color: #666;
    }
    &:active {
        border-width: 2px;
    }
`;

const BlockExplorerLink = styled( Link )`
    background-color: ${ colors.blue };
    
    &:hover {
        background-color: ${ colors.blue }AA;
    }
`;

const DogeSwapLink = styled( Link )`
    background-color: ${ colors.pink };
    
    &:hover {
        background-color: ${ colors.pink }AA;
    }
`;

const CopyButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 0 none;
    background: transparent;
    padding: 0;
    margin: 0 0.4rem 0 0;
    cursor: pointer;
    
    ${ Text }{
        margin-left: 0.5rem;
    }
`;

const Buttons: React.FC = () => {
    const [ copied, setCopied ] = useState( false );

    const handleCopyClicked = () => navigator.clipboard
                                             .writeText( TOKEN_ADDRESS )
                                             .then( () => {
                                                 setCopied( true );
                                                 setTimeout( () => setCopied( false ), 4000 );
                                             } )
                                             .catch( () => alert( 'Error copying text to clipboard.' ) );

    return (
        <ContentWrapper>
            <ContractAddressWrapper>
                <CopyButton onClick={ handleCopyClicked }>
                    <CopyIcon color={ colors.pink }/>
                    <Text bold underline textWrap>
                        { TOKEN_ADDRESS }
                    </Text>
                </CopyButton>

                { copied && (
                    <CopiedMessage>
                        <CheckmarkIcon color={ colors.blue }/>Address copied to clipboard
                    </CopiedMessage>
                ) }
            </ContractAddressWrapper>

            <LinksWrapper>
                <BlockExplorerLink
                    href={ `https://explorer.dogechain.dog/token/${ TOKEN_ADDRESS }` }
                    target='_blank'
                    rel='noreferrer nofollow'
                >
                    Block Explorer
                </BlockExplorerLink>

                <DogeSwapLink
                    href={ `https://dogeswap.org/#/swap?outputCurrency=${ TOKEN_ADDRESS }` }
                    target='_blank'
                    rel='noreferrer nofollow'
                >
                    DogeSwap Buy
                </DogeSwapLink>
            </LinksWrapper>
        </ContentWrapper>
    );
};

export default Buttons;