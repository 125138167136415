import React from 'react';
import { Box, BoxProps } from '..';

const Container: React.FC<BoxProps> = ( { children, ...props } ) => (
    <Box
        mx="auto"
        width='100%'
        maxWidth="1200px"
        { ...props }
    >
        { children }
    </Box>
);

export default Container;
