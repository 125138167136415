import React from 'react';
import styled from 'styled-components';
import { Flex, Text } from 'components';
import colors from 'config/theme/colors';
import { mediaQueries } from 'config/theme/base';

import Buttons from './Buttons';
import DragogeVideoMp4 from 'assets/videos/dragoge.mp4';
import DragogeVideoWebm from 'assets/videos/dragoge.webm';
import DragogeVideoOgg from 'assets/videos/dragoge.ogg';

const InnerContainer = styled.div`
    width: 100%;
    max-width: 760px;
    margin: 0 auto;
`;

const DetailsWrapper = styled( Flex )`
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 4rem;
    padding: 16px;
    
    ${ mediaQueries.md }{
        margin-bottom: 0;
    }
`;

const Video = styled.video`
    width: 100%;
    height: 100%;
    border: 6px solid ${ colors.green };
    z-index: 9;
    margin-bottom: 1rem;
    
    ${ mediaQueries.md }{
        margin-bottom: 0;
        margin-top: 1rem;
        margin-bottom: 2rem;
    }
`;

const GogeHeading = styled( Text ).attrs( { bold: true } )`
    margin-top: 0;
`;

const Memes: React.FC = () => {
    return (
        <InnerContainer>
            <DetailsWrapper id='memes'>
                <GogeHeading fontSize='42px' textAlign='center'>GOGE</GogeHeading>
                <Video
                    loop
                    preload="auto"
                    autoPlay
                    muted
                    playsInline
                >
                    <source src={ DragogeVideoMp4 } type="video/mp4"/>
                    <source src={ DragogeVideoWebm } type="video/webm"/>
                    <source src={ DragogeVideoOgg } type="video/ogg"/>
                    <span>Your browser does not support the video tag.</span>
                </Video>
                <Text textAlign='center' fontSize='24px' textWrap>
                    GOGE IS THE GOD OF DOGE, THE DAO, AND THE PATH TO THE GREAT LIBERATION THROUGH THE YOGA OF KNOWING THE ONE MEME
                </Text>
                <Buttons/>
            </DetailsWrapper>
        </InnerContainer>
    );
};

export default Memes;