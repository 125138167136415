import React from 'react';
import styled from 'styled-components';
import DogeFace from 'assets/images/dogeface.png';
import { mediaQueries } from 'config/theme/base';
import { Flex } from '../Box';

const FunImage = styled.img`
    width: 121px;
    height: 115px;
    margin-right: 3rem;
    
    ${ mediaQueries.md }{
        width: 230px;
        height: 218px;
    }
`;

const PageFooter: React.FC = () => {
    return (
        <Flex width='100%' alignItems='flex-end' justifyContent='flex-end'>
            <FunImage src={ DogeFace } alt='Doge'/>
        </Flex>);
};

export default PageFooter;