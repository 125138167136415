import React, { useState } from 'react';
import { Flex } from '../Box';
import Link from './Link';
import { HamburgerIcon, MediumIcon, TelegramIcon, TwitterIcon } from '../Svg';
import styled from 'styled-components';
import colors from 'config/theme/colors';
import { mediaQueries } from '../../config/theme/base';
import { socials } from './config';

const Wrapper = styled( Flex )`
    position: relative;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
    ${ mediaQueries.md }{
        display: none;
    }
`;

const VisibleMenu = styled.div`
    background: ${ colors.yellow };
    position: absolute;
    top: -1px;
    right: 36px;
    padding: 10px 30px;
    z-index: 10;
`;

const ToggleButton = styled.button`
    background: ${ colors.yellow };
    border: 0px none;
    padding: 8px;
`;

const MobileMenu: React.FC = () => {
    const [ menuVisible, setMenuVisible ] = useState( false );

    return (
        <Wrapper>
            { menuVisible && (
                <VisibleMenu>
                    <Flex
                        flexDirection='row'
                        alignItems='center'
                        justifyContent='center'
                    >
                        <Link mr={ 24 } url={ socials.TELEGRAM } icon={ TelegramIcon }/>
                        <Link mr={ 24 } url={ socials.TWITTER } icon={ TwitterIcon }/>
                        <Link url={ socials.MEDIUM } icon={ MediumIcon }/>
                    </Flex>
                </VisibleMenu>
            ) }
            <ToggleButton onClick={ () => setMenuVisible( !menuVisible ) }>
                <HamburgerIcon width={ 24 }/>
            </ToggleButton>
        </Wrapper>
    );
};

export default MobileMenu;