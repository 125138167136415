export const menuItems = {
    HOME: 'memes',
    ABOUT: 'about',
    LOTTERY: 'lottery',
}

export const socials = {
    TELEGRAM: 'https://t.me/gogedog',
    TWITTER: 'https://twitter.com/gogedog',
    MEDIUM: 'https://medium.com/gogedog',
}