import React from 'react';
import styled from 'styled-components';
import { PageFooter, PageHeader } from 'components';
import { Flex } from '../Box';

const PageWrapper = styled( Flex )`
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`;

const BodyWrapper = styled.div`
    width: 100%;
    flex: 1;
`;

const Page: React.FC = ( props ) => {
    const { children } = props;

    return (
        <PageWrapper>
            <PageHeader/>
            <BodyWrapper>
                { children }
            </BodyWrapper>
            <PageFooter/>
        </PageWrapper>
    );
};

export default Page;