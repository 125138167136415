import styled from 'styled-components';
import { background, border, layout, position, space } from 'styled-system';
import { BoxProps } from './types';

const Box = styled.div<BoxProps>`
    ${ ( { bordered } ) => bordered ?
    `border: 1px solid #ccc;
    border-radius: 16px;
    padding: 1rem;` : border }
    
    ${ background }
    ${ layout }
    ${ position }
${ space }
`;

export default Box;
