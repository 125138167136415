import React from 'react';
import { Flex } from '../Box';
import Link from './Link';
import { MediumIcon, TelegramIcon, TwitterIcon } from '../Svg';
import styled from 'styled-components';
import { mediaQueries } from '../../config/theme/base';
import { socials } from './config';

const Wrapper = styled.div`
    flex: 1;
    display: none;
    ${ mediaQueries.md }{
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;
const DesktopMenu: React.FC = () => {
    return (
        <Wrapper>
            <Flex
                alignItems='center'
                justifyContent='center'
            >
                <Link mr={ 24 } url={ socials.TELEGRAM } icon={ TelegramIcon }/>
                <Link mr={ 24 } url={ socials.TWITTER } icon={ TwitterIcon }/>
                <Link url={ socials.MEDIUM } icon={ MediumIcon }/>
            </Flex>
        </Wrapper>
    );
};

export default DesktopMenu;