import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    html, body {
        height: 100vh;
    }
    
    body {
        background-color: #ffffff;
        color: #000000;
        
        img {
            height: auto;
            max-width: 100%;
        }
    }
    
    #root {
        height: 100%;
    }
    
    * {
        font-family: 'Comic Neue', cursive;
        font-size: 18px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
`;

export default GlobalStyle;
