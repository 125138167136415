import React, { FC } from 'react';
import styled from 'styled-components';
import { SvgProps } from 'components';
import colors from 'config/theme/colors';
import { space, SpaceProps } from 'styled-system';

const Anchor = styled.a`
    display: block;
    
    svg {
        transition: transform, fill, 0.3s;
    }
    
    &:hover {
        svg {
            transform: scale(1.1);
            fill: rgba(0, 0, 0, 0.75);
        }
    }
    ${ space }
`;

interface LinkProps extends SpaceProps {
    url: string
    icon: FC<SvgProps>;
}

const Link: React.FC<LinkProps> = ( props ) => {
    const { url, icon: Icon, ...otherProps } = props;

    return (
        <Anchor href={ url } target='_blank' rel='noreferrer' { ...otherProps }>
            <Icon color={ colors.blue } width={ 24 }/>
        </Anchor>
    );
};

export default Link;