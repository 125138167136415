import React from 'react';
import { Page } from 'components';
import Memes from './components/Memes';

const Home: React.FC = () => {
    return (
        <Page>
            <Memes/>
        </Page>
    );
};

export default Home;