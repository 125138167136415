import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import GlobalStyle from 'style/Global';
import ResetCSS from './style/ResetCSS';

import Home from './views/Home';
import NotFound from './views/NotFound';

const App: React.FC = () => {
    return (
        <BrowserRouter>
            <ResetCSS/>
            <GlobalStyle/>
            <Switch>
                <Route exact path='/'>
                    <Home/>
                </Route>
                <Route>
                    <NotFound/>
                </Route>
            </Switch>
        </BrowserRouter>
    );
};

export default React.memo( App );
